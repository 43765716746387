
import { ApiError, EVENT_TOPIC_API_ERROR, RequestJSONResult } from "@velites/api";
import { PREDEFINED_ORDERS } from "@velites/common";
import { EventHandlingContext, withEventHandlingContext } from "@velites/event";
import { STATUS_TYPE_API_INVOKING } from "@velites/states";
import {
  catchError,
  delay,
  filter,
  forkJoin,
  from,
  fromEvent,
  interval,
  map,
  mapTo,
  Observable,
  of,
  pairwise,
  retryWhen,
  Subject,
  Subscription,
  switchMap,
  take,
  timer,
} from "rxjs";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class extends Vue {
  private errSubscription?: Subscription;
  sObj?: Subscription | undefined;
  list$ = new Subject();
  testModalVisible = false;
  value1 = "";
  trackIngConfig = {
    id: "",
  };
  options: any[] = [
    {
      value: "Option1",
      label: "Option1",
    },
    {
      value: "Option2",
      label: "Option2",
    },
    {
      value: "Option3",
      label: "Option3",
    },
    {
      value: "Option4",
      label: "Option4",
    },
    {
      value: "Option5",
      label: "Option5",
    },
  ];
  userInfo = {
    id: "1",
    name: "222",
    age: 2,
  };

  optionConfig = {};

  dataList: any[] = [];
  mounted(): void {
    this.init();
    setTimeout(() => {
      let obj;
      for (let i = 0; i < 11; i++) {
        obj = {
          id: "0c82cc4e8999f412cc0de21ddf68d956",
        };
        this.dataList.push(obj);
      }
    }, 5000);
  }

  queryInfo() {
    // 查询信息
    console.log("埋点之后，走查询的信息", new Date().getTime());
  }

  init() {
    this.errSubscription = this.$event.subscribeEvent(
      [EVENT_TOPIC_API_ERROR, "opt.login.success"],
      (res: any) => {
        console.log("normal", res);
        switch (res.topic) {
          default:
            break;
        }
      }
    );
    this.$event.subscribeEventwithEventHandlingContext(
      [EVENT_TOPIC_API_ERROR],
      (res, ctx) => {
        console.log("pre-intercept", res);
        ctx.interrupt(); // 这里指令：在本次事件发送里，后续还没执行的事件回调不再执行
      },
      { order: PREDEFINED_ORDERS.EARLIER } // 这里指定按照 order 的顺序来执行对应topic事件的所有回调， 从小到大
    );
    console.log('init test');
  }

  openApi() {
    this.sObj?.unsubscribe();
    this.sObj = interval(1000).subscribe((_c) => {
      if (this.dataList.length > 10) {
        console.log("重试大于10次停止重试 " + this.dataList.length);
        this.sObj?.unsubscribe();
      } else {
        console.log("错误重试: " + this.dataList.length);
      }
    });
  }

  openApi1() {
    const res = this.identity({ aa: "111" });
    console.log(res);
  }

  identity<T>(arg: T): T {
    return arg;
  }

  unmounted(): void {
    if (this.errSubscription) {
      this.errSubscription.unsubscribe();
    }
  }
  value0: any = "abcde";
  colorItem: any = null;
  currentColor = "red";
  get apiProgress(): boolean {
    return this.$status.obtainStatuses(STATUS_TYPE_API_INVOKING).length > 0;
  }
  get user() {
    return this.obtainAccount()?.displayName;
  }
  async doPocket1() {
    [this.value0] = await this.$api.get("testget1");
    this.currentColor = "red";
    this.colorItem = this.pocketItem("color", "red");
  }
  async doPocket2() {
    [this.value0] = await this.$api.get("testget2");
    this.currentColor = "reblued";
  }
  async doError1() {
    await this.$api.get("testerr1");
  }
  async doDelay1() {
    await this.$api.get("test-misc/delay");
  }
  async exception() {
    await this.$api.get("test-exception/exCoded1");
  }

  doTrack1() {
    this.$tracker.track("abc.1", {
      behavior: "test.behavior.1",
      detail: { p1: 12, p2: "aa", p3: { pp1: 3, pp2: "lls" } },
    });
  }

  timeChange(_res: any) {}
}
